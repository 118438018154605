import React from 'react';
import automation from '../../assets/images/tableau-de-bord.png';
import invoice from '../../assets/images/calculation.png';
import payment from '../../assets/images/analytics.png';
import rapport from '../../assets/images/rapport-dactivite.png';
import survey from '../../assets/images/surveyor.png';

const Fonctionnalite = () => {
  const fonctionnalites = [
    { title: 'Syncro Sales', imgSrc: payment, description: 'Pilotez vos cycles de vente et améliorez vos taux de conversion jusqu’à 30%' },
    { title: 'Devis & Factures', imgSrc: invoice, description: 'Simplifiez vos transactions avec des documents personnalisés en quelques clics' },
    { title: 'Tableau de Bord', imgSrc: automation, description: 'Visualisez vos performances en temps réel pour des décisions stratégiques immédiates' },
    { title: 'Rapports', imgSrc: rapport, description: 'Alignez vos actions sur vos objectifs grâce à des analyses de données avancées' },
    { title: 'Sondage', imgSrc: survey, description: 'Optimisez vos enquêtes pour recueillir des informations cruciales rapidement et efficacement' },
  ];

  return (
    <div style={containerStyle}>
<h2 className="font-roboto uppercase font-bold text-2xl tracking-wider">
  F O N C T I O N N A L I T É S
</h2>



      <p style={descriptionStyle}>
        Avec Syncro, chaque commercial dispose enfin des outils pour atteindre ses objectifs, chaque interaction devient une opportunité, et chaque opportunité se transforme en succès.
      </p>
      <div style={trackContainerStyle}>
        <div style={trackStyle}>
          {fonctionnalites.map((item, index) => (
            <React.Fragment key={index}>
              <div style={itemStyle}>
                <img src={item.imgSrc} alt={item.title} style={imageStyle} />
                <h3 style={itemTitleStyle}>{item.title}</h3>
                <p style={itemDescriptionStyle}>{item.description}</p>
              </div>
              {index < fonctionnalites.length - 1 && <div style={verticalLineStyle}></div>}
            </React.Fragment>
          ))}
        </div>
      </div>

      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}
      </style>
    </div>
  );
};

const containerStyle = {
  padding: '2rem 1rem',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#ffffff',
};

const titleStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '1rem',
  color: 'black',
};

const descriptionStyle = {
  fontSize: '1rem',
  textAlign: 'center',
  marginBottom: '2rem',
  maxWidth: '800px',
};

const trackContainerStyle = {
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
};

const trackStyle = {
  display: 'flex',
  gap: '2rem',
  animation: 'scroll 40s linear infinite',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '200%',
};

const itemStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  minWidth: '200px',
};

const imageStyle = {
  width: '100px',
  height: '100px',
  objectFit: 'contain',
  marginBottom: '0.5rem',
};

const itemTitleStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: '#331e62',
};

const itemDescriptionStyle = {
  fontSize: '0.9rem',
  color: '#555',
  marginTop: '0.5rem',
};

const verticalLineStyle = {
  height: '100px',
  width: '2px',
  backgroundColor: '#ccc',
};

export default Fonctionnalite;
