import React from 'react';
import image1 from '../../assets/images/excellenceabout.avif';
import image2 from '../../assets/images/accessaboutus.jpg';

const Vision = () => {
  const visions = [
    {
      title: 'Excellence',
      description: "Nous nous engageons à offrir des solutions qui repoussent les limites de l'excellence et anticipent les défis de demain. Grâce à des technologies de pointe, comme l’intelligence artificielle, nous façonnons un avenir où chaque entreprise peut réaliser son plein potentiel",
      image: image1,
    },
    {
      title: 'Accessibilité',
      description: "Nous croyons qu’une solution premium doit être accessible à toutes les entreprises, quelle que soit leur taille ou leur secteur. C’est pourquoi nous rendons nos solutions innovantes abordables, alliant qualité, performance et prix accessibles.",
      image: image2,
    },
  ];

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3rem',
    padding: '2rem',
    marginTop: '3rem',
    backgroundColor: '#f7f7f7',
  };

  const imagesContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '2rem',
    width: '100%',
    maxWidth: '1100px',
    justifyItems: 'center',
  };

  const titleOverlayStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0.8rem',
    color: '#fff',
    fontSize: '1.4rem',
    fontWeight: '500',
  };

  const cardStyle = {
    perspective: '1000px',
    width: '90%',
    height: '250px',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: '10px',
    overflow: 'hidden',
  };

  const cardInnerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 0.5s ease-in-out',
    transformStyle: 'preserve-3d',
    transformOrigin: 'center',
  };

  const cardFrontBackStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    borderRadius: '10px',
    overflow: 'hidden',
  };

  const cardFrontStyle = {
    ...cardFrontBackStyle,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const cardBackStyle = {
    ...cardFrontBackStyle,
    backgroundColor: '#20c5e2',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    transform: 'rotateY(180deg)',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.querySelector('.card-inner').style.transform =
      'rotateY(180deg)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.querySelector('.card-inner').style.transform = 'rotateY(0deg)';
  };

  const textContainerStyle = {
    textAlign: 'center',
    padding: '2rem',
    maxWidth: '900px',
    margin: '0 auto',
    backgroundColor: '#fff',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  };

  const paragraphStyle = {
    fontSize: '1.2rem',
    lineHeight: '1.5',
    color: '#555',
    marginTop: '1rem',
  };

  return (
    <div style={containerStyle}>
      <div style={imagesContainerStyle}>
        {visions.map((vision, index) => (
          <div
            key={index}
            style={cardStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="card-inner" style={cardInnerStyle}>
              <div
                className="card-front"
                style={{ ...cardFrontStyle, backgroundImage: `url(${vision.image})` }}
              ></div>
              <div style={titleOverlayStyle}>{vision.title}</div>
              <div className="card-back" style={cardBackStyle}>
                <h3>{vision.title}</h3>
                <p>{vision.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div style={textContainerStyle}>
        <h2 style={{ fontSize: '2rem', fontWeight: '600', marginBottom: '1rem' }}>
          Ensemble concrétisons vos ambitions
        </h2>
        <p style={paragraphStyle}>
          Votre réussite est notre priorité. En vous proposant des solutions innovantes et un accompagnement personnalisé, nous vous accompagnons à chaque étape de vos objectifs. Ensemble, transformons votre vision en succès !
        </p>
      </div>
    </div>
  );
};

export default Vision;
