import React from 'react';
import { FaEnvelope, FaGlobe, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import topcontact from '../assets/images/backPricing.jpg';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';

const headerSectionStyle = {
    position: 'relative',
    width: '100%',
    height: '68vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundImage: `url(${topcontact})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    padding: '0 1rem',
};

const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const headerTextContainerStyle = {
    position: 'relative',
    zIndex: 1,
    animation: 'fadeIn 1s ease-in-out forwards',
    marginTop: '3rem',
};

const headerBoldTextStyle = {
    fontWeight: 'bold',
    fontSize: 'clamp(2rem, 3vw, 3rem)',
    fontFamily: 'Lato, sans-serif',
    color: '#ffffff',
    animation: 'blinkText 2s infinite',
};

const headerRegularTextStyle = {
    fontSize: 'clamp(1rem, 1.5vw, 1.2rem)',
    fontWeight: 'normal',
    marginTop: '0.5rem',
    fontFamily: 'Lato, sans-serif',
    color: '#ffffff',
    animation: 'blinkText 2s infinite',
    animationDelay: '0.5s',
};

const pageContentStyle = {
    padding: '2rem',
    backgroundColor: '#ffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    fontFamily: 'Lato, sans-serif',
};

const contentContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '1200px',
    gap: '2rem',
    marginTop: '2rem',
    flexWrap: 'wrap',
};

const formContainerStyle = {
    flex: '2',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    minWidth: '300px',
};
const infoContainerStyle = {
    flex: '1',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    minWidth: '300px',
};

const formFieldStyle = {
    width: '100%',
    padding: '0.8rem',
    marginBottom: '1rem',
    borderRadius: '5px',
    border: '2px solid #4eb1e2',
    backgroundColor: 'transparent',
    color: '#ffffff',
    fontFamily: 'Lato, sans-serif',
};

const submitButtonStyle = {
    width: '100%',
    padding: '0.8rem',
    backgroundColor: '#4eb1e2',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    fontSize: '1rem',
    fontFamily: 'Lato, sans-serif',
};

const mapContainerStyle = {
    width: '100%',
    height: '500px',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginTop: '2rem',
};

const Contact = () => {
    return (
        <>
            <Header />
            <div style={headerSectionStyle}>
                <div style={overlayStyle}></div>
                <div style={headerTextContainerStyle}>
                    <div style={headerBoldTextStyle}>Et si nous parlions de vos projets ?</div>
                    <br />
                    <div style={headerRegularTextStyle}>
                        Chaque demande est unique pour nous. Rencontrons-nous pour discuter de vos besoins et créer ensemble une solution sur mesure.
                        <br></br>Café offert !
                    </div>
                </div>
            </div>

            <div style={pageContentStyle}>
                <div style={contentContainerStyle}>
                    <div style={formContainerStyle}>
                        <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', color: '#ffffff', marginBottom: '1rem' }}>
                            Contactez - nous !
                        </h2>
                        <form>
                            <input type="text" placeholder="Nom complet*" style={formFieldStyle} />
                            <input type="text" placeholder="Nom de l'entreprise*" style={formFieldStyle} />
                            <input type="tel" placeholder="Téléphone*" style={formFieldStyle} />
                            <input type="email" placeholder="Email*" style={formFieldStyle} />
                            <textarea placeholder="Votre Message*" rows="4" style={formFieldStyle}></textarea>
                            <button type="submit" style={submitButtonStyle}>Envoyer</button>
                        </form>
                    </div>

                    <div style={infoContainerStyle}>
                        <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', color: '#ffffff', marginBottom: '1rem' }}>
                            Nos Coordonnées
                        </h2>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', marginBottom: '1rem', color: '#ffffff' }}>
                            <FaMapMarkerAlt style={{ marginRight: '0.5rem', color: '#4eb1e2' }} />
                            <span>Avenue Bourguiba, Immeuble Ndama</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', marginBottom: '1rem', color: '#ffffff' }}>
                            <FaPhoneAlt style={{ marginRight: '0.5rem', color: '#4eb1e2' }} />
                            <span>+221 77 251 88 88</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', marginBottom: '1rem', color: '#ffffff' }}>
                            <FaEnvelope style={{ marginRight: '0.5rem', color: '#4eb1e2' }} />
                            <span>contact@syncro.sn</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', marginBottom: '1rem', color: '#ffffff' }}>
                            <FaGlobe style={{ marginRight: '0.5rem', color: '#4eb1e2' }} />
                            <span>
                                <a href="https://www.syncro.sn" target="_blank" rel="noopener noreferrer" style={{ color: '#4eb1e2', textDecoration: 'none' }}>
                                    www.syncro.sn
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <div style={mapContainerStyle}>
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.951530223472!2d-17.45125548469002!3d14.693431880338104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec172f1e54d85d9%3A0x6d6b0eb9a8162fe5!2sAvenue%20Bourguiba%2C%20Dakar%2C%20S%C3%A9n%C3%A9gal!5e0!3m2!1sen!2sus!4v1634044309140!5m2!1sen!2sus"
                        width="100%"
                        height="150%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
