import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import bannerImage from '../../assets/images/1.png';
import syncroLogo from '../../assets/images/logosyncro.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleLanguage = () => setIsEnglish(!isEnglish);

  const handleDemoClick = () => {
    navigate('/');
    setTimeout(() => {
      const contactSection = document.querySelector('[data-contact-section]');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <>
      <style>
        {`
          .banner {
            width: 100%;
            height: 40px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
          }

          .banner-image {
            width: 100%;
            height: 100%;
            background-image: url(${bannerImage});
            background-size: cover;
            background-position: center;
            opacity: 0.8;
            filter: brightness(80%);
          }

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 1rem 1rem;
            background-color: #ffffff;
            border-bottom: 1px solid #ddd;
            position: fixed;
            top: 40px;
            z-index: 1000;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          }

          .logo {
            width: 150px;
            height: auto;
          }

          .nav {
            display: flex;
            gap: 1rem;
            align-items: center;
          }

          .nav-mobile {
            display: none;
            flex-direction: column;
            gap: 1rem;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: #fff;
            padding: 1rem;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            z-index: 999;
          }

          .nav-link {
            color: #331e62;
            font-weight: bold;
            font-size: 1rem;
            text-decoration: none;
            padding: 0.5rem 1rem;
            transition: color 0.3s, background-color 0.3s;
            border-radius: 5px;
          }

          .nav-link.active {
            color: #20c5e2;
            background-color: #e0f7fa;
          }

          .demo-button {
            padding: 0.5rem 1.5rem;
            background: linear-gradient(45deg, #331e62, #20c5e2);
            color: #fff;
            border: none;
            border-radius: 30px;
            cursor: pointer;
            font-size: 1rem;
            text-decoration: none;
            transition: transform 0.3s ease;
            white-space: nowrap;
            font-weight: bold;
            font-family: Raleway;
          }

          .toggle-container {
            display: flex;
            align-items: center;
            position: relative;
            width: 50px;
            height: 25px;
            background-color: #002244;
            border-radius: 15px;
            cursor: pointer;
          }

          .toggle-circle {
            width: 25px;
            height: 25px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            transition: left 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            font-weight: bold;
            color: #002244;
          }

          .toggle-circle.en {
            left: 50%;
          }

          .toggle-circle.fr {
            left: 0;
          }

          @media (max-width: 768px) {
            .nav {
              display: none;
            }

            .nav-mobile {
              display: flex;
            }
          }
        `}
      </style>

      {/* Bande au-dessus du header */}
      <div className="banner">
        <div className="banner-image"></div>
      </div>

      {/* Header */}
      <header className="header">
        <img src={syncroLogo} alt="Syncro Logo" className="logo" />

        {/* Menu Hamburger */}
        {isMobile && (
          <div style={{ cursor: 'pointer', fontSize: '1.5rem' }} onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
        )}

        {/* Navigation Links */}
        <nav className={isMobile && isMenuOpen ? 'nav-mobile' : 'nav'}>
          <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Accueil</Link>
          <Link to="/about-us" className={`nav-link ${location.pathname === '/about-us' ? 'active' : ''}`}>Qui sommes nous ?</Link>
          <Link to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Link>

          {isMobile && (
            <>
              <button className="demo-button" onClick={handleDemoClick}>Demander une démo</button>
              <div className="toggle-container" onClick={toggleLanguage}>
                <div className={`toggle-circle ${isEnglish ? 'en' : 'fr'}`}>{isEnglish ? 'EN' : 'FR'}</div>
              </div>
            </>
          )}
        </nav>

        {/* Bouton Démo et Toggle Langue (Desktop) */}
        {!isMobile && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <button className="demo-button" onClick={handleDemoClick}>Demander une démo</button>
            <div className="toggle-container" onClick={toggleLanguage}>
              <div className={`toggle-circle ${isEnglish ? 'en' : 'fr'}`}>{isEnglish ? 'EN' : 'FR'}</div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;