import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import {
  FaGlobe,
  FaProjectDiagram,
  FaRegBuilding,
  FaUsers
} from "react-icons/fa";

import backImg from '../assets/images/296276.jpg';
import atlassian from '../assets/images/atlassian.png';
import begood from '../assets/images/begood.png';
import bravo from '../assets/images/bravo.png';
import canva from '../assets/images/canva.png';
import google from '../assets/images/google.png';
import digitadvisor from '../assets/images/digitadvisor.png';
import eagles from '../assets/images/eagles.png';
import ImageAccueil from '../assets/images/imageapp.png';
import karam from '../assets/images/karam.png';
import kora from '../assets/images/kora.png';
import kwiku from '../assets/images/kwiku.png';
import meta from '../assets/images/meta.png';
import microsoft from '../assets/images/microsoft.webp';
import platvoy from '../assets/images/platvoy.png';
import slack from '../assets/images/slack.png';
import sunubet from '../assets/images/sunubet.png';
import unacois from '../assets/images/unacois.png';
import woocommerce from '../assets/images/woocommerce.webp';
import free from '../assets/images/yas.svg';
import zoom from '../assets/images/zooom.png';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';
import NewContact from '../components/Common/NewContact';
import DemoVideoSection from '../components/Home/DemoVideoSection';
import Fonctionnalite from '../components/Home/FonctionnaliteSection';
import Testimonials from '../components/Home/TestimonialsSection';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const statsRef = useRef(null);
  const introRef = useRef(null);

  useEffect(() => {
    // Animations GSAP
    gsap.fromTo(
      introRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );

    gsap.fromTo(
      statsRef.current.children,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.3,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: statsRef.current,
          start: 'top 80%',
        },
      }
    );
  }, []);


const styleConteneurEnTete = {
   position: 'relative', // Pour positionner les enfants en absolu
   width: '100%', // Prend toute la largeur de l'écran
   height: '100vh', // Prend toute la hauteur de la fenêtre
   overflow: 'hidden', // Empêche les débordements
 };
 
 const styleImage = {
   position: 'absolute', // Permet à l'image de servir d'arrière-plan
   top: 0,
   left: 0,
   width: '100%', // Couvre toute la largeur
   height: '100%', // Couvre toute la hauteur
   objectFit: 'cover', // Ajuste l'image pour couvrir l'espace sans la déformer
   zIndex: -1, // Place l'image derrière le contenu
 };
 
 const styleSuperposition = {
   position: 'absolute',
   top: 0,
   left: 0,
   width: '100%',
   height: '100%',
   backgroundColor: 'rgba(0, 0, 0, 0.5)',
   zIndex: 0, // Sous le texte mais au-dessus de l'image
 };
 
 const styleContenu = {
   position: 'absolute', // Permet de positionner le texte au centre
   top: '50%', // Centre verticalement
   left: '50%', // Centre horizontalement
   transform: 'translate(-50%, -50%)', // Ajuste le point de référence pour centrer
   zIndex: 1, // Place le texte au-dessus de l'overlay
   color: 'white',
   textAlign: 'center',
 };
 
 const styleTitre = {
   fontSize: 'clamp(3rem, 5vw, 7.5rem)', // Taille responsive pour le titre
   fontWeight: 'bold',
   textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)', // Légère ombre pour contraster
 };
 
 const styleSousTitre = {
   fontSize: 'clamp(1.2rem, 2.5vw, 2rem)', // Taille responsive pour le sous-titre
   marginTop: '1rem',
   textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)', // Légère ombre pour contraster
 };
 
 const conteneur = {
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   width: "100%",
   marginTop: "2rem",
 };

  // Référence pour la section NewContact
  const contactRef = useRef(null);

  const scrollToContact = () => {
    // Défile jusqu'à la section NewContact
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

 const image = {
   width: "100%",
   maxWidth: "1200px",
 };

  const introStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    flexWrap: 'wrap',
    padding: '2rem',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    maxWidth: '100%',
    margin: '0 auto',
  };

  const introTextStyle = {
    flex: '1',
    minWidth: '300px',
    textAlign: 'center',
  };

  const carouselRef = useRef(null);

useEffect(() => {
  let angle = 0;

  const rotateCarousel = () => {
    if (carouselRef.current) {
      angle -= 0.5; // Ajuster la vitesse de défilement
      carouselRef.current.style.transform = `rotateY(${angle}deg)`;
    }
    requestAnimationFrame(rotateCarousel);
  };

  rotateCarousel();
}, []);


  return (
<section>

<Header />

<header style={styleConteneurEnTete}>
  <img src={backImg} alt="Fond" style={styleImage} />
  <div style={styleSuperposition}></div>
  <div style={styleContenu}>
    <h1 style={styleTitre}>S Y N C R O</h1>
    <p style={styleSousTitre}>Votre allié vers l'excellence</p>
  </div>

  <style>
    {`
      @media (max-width: 468px) {
        h1 {
          font-size: 2rem; /* Ajuste la taille du titre sur les petits écrans */
        }
        p {
          font-size: 1rem; /* Ajuste la taille du sous-titre sur les petits écrans */
        }
      }
      @media (min-width: 769px) and (max-width: 1024px) {
        h1 {
          font-size: 2.5rem; /* Taille intermédiaire pour les tablettes */
        }
        p {
          font-size: 1.2rem; /* Taille intermédiaire pour le sous-titre */
        }
      }
      @media (min-width: 1025px) {
        h1 {
          font-size: 3rem; /* Taille par défaut pour les grands écrans */
        }
        p {
          font-size: 1.5rem; /* Taille par défaut pour le sous-titre */
        }
      }
    `}
  </style>
</header>


{/* Description Section */}
<div ref={introRef} style={introStyle}>
  <div style={introTextStyle}>
    <h2
      style={{
        fontSize: "2.5rem",
        fontWeight: "",
        color: "#20c5e2",
      }}
    >
      Bienvenue dans l’univers Syncro
      <br />
      La Solution Cloud Premium accessible à tous
    </h2>
    <p
      style={{fontSize: "1rem",lineHeight: "1.6",color: "#333",margin: "1rem 0",}}>
      À la pointe de l'innovation, Syncro centralise la gestion de vos ressources
      humaines, projets, ventes et finances pour maximiser votre productivité.
      Que vous soyez une TPE, une PME ou une grande entreprise, Syncro simplifie
      la gestion de vos données, automatise vos processus et renforce la
      collaboration au sein de vos équipes. Modulable et intuitive, Syncro
      s’adapte à vos besoins et transforme chaque interaction client en une
      opportunité de service personnalisé.
    </p>

    {/* GIF ajouté ici */}
    <img
      src={require("../assets/images/homegif.gif")}
      alt="Syncro Animation"
      style={{
        display: "block",
        margin: "1rem auto",
        width: "200px",
        animation: "float 4s ease-in-out infinite",
      }}
    />
  </div>
</div>
<br></br>
<div
  style={{display: "flex", justifyContent: "center",alignItems: "center",height: "60vh",backgroundColor: "black",width: "70%",
    maxWidth: "1000px", margin: "0 auto",borderRadius: "15px",boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",overflow: "hidden",}}>
  <DemoVideoSection />
</div>

   <br></br>
   <div style={{display: "flex",justifyContent: "center",alignItems: "center",marginTop: "2rem",}}>
        <button
          style={{
            padding: "15px 40px",
            fontSize: "1.5rem",
            background: "linear-gradient(90deg, #331e62, #20c5e2)",
            color: "#fff",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
            fontWeight: "bold",
            transition: "all 0.3s ease",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = "scale(1.1)";
            e.target.style.boxShadow = "0px 6px 15px rgba(0, 0, 0, 0.4)";
            e.target.style.background =
              "linear-gradient(90deg, #20c5e2, #331e62)";
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = "scale(1)";
            e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.3)";
            e.target.style.background =
              "linear-gradient(90deg, #331e62, #20c5e2)";
          }}
          onClick={scrollToContact} // Défilement vers la section contact
        >
          Demander une démo
        </button>
      </div>

<br></br>
{/* PARTIE LES SYNCRO*/}
   <Fonctionnalite/>

{/* PARTIE ANTA IA */}
<div style={{ backgroundColor: '#ffffff', padding: '50px 20px', textAlign: 'center' }}>
   <motion.h2
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      style={{
         fontSize: '2rem',
         fontWeight: 'bold',
         background: 'linear-gradient(90deg, #feb91c, #20c5e2, #331e62)',
         WebkitBackgroundClip: 'text',
         WebkitTextFillColor: 'transparent',
         marginBottom: '20px',
      }}
   >
      Anta IA bientôt là pour transformer votre futur avec Syncro
   </motion.h2>
   <motion.p
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1, delay: 0.5 }}
      style={{
         fontSize: '1.5rem',
         lineHeight: '1.8',
         maxWidth: '800px',
         margin: '0 auto',
         marginBottom: '40px',
         color: 'black',
      }}
   >
      Découvrez comment Anta IA peut redéfinir vos processus, simplifier les tâches complexes 
      et vous propulser dans une nouvelle ère d'innovation. <br></br>Êtes-vous prêt à libérer son potentiel ?
   </motion.p>
   <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.8 }}
      style={{
         width: '100%',
         margin: '0 auto',
      }}
   >
      <img
         src={require('../assets/images/iaanta.png')}
         alt="Anta IA"
         style={{
            width: '100%', // Prend toute la largeur
            height: 'auto', // Garde les proportions originales
            display: 'block', // Empêche les espaces sous l'image
         }}
      />
   </motion.div>
</div>


{/* PARTIE ADAPTER A TOUS */}
<div style={conteneur}>
<h2 className="uppercase font-bold text-2xl font-roboto tracking-wide text-center"> une solution adaptée à tous vos supports </h2>
      <img
        src={ImageAccueil}
        alt="Illustration des supports"
        style={image}
      />
    </div>

{/* PARTIE CHIFFRES  */}
<h2 className="text-2xl font-bold text-center mt-16 uppercase font-roboto tracking-wide">
  SYNCRO À TRAVERS LES CHIFFRES
</h2>
<div style={{ display: 'grid',gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '4rem', padding: '2rem',justifyContent: 'center', textAlign: 'center',}}ref={statsRef}>

   <div style={{display: 'flex',flexDirection: 'column',alignItems: 'center',padding: '2rem',}}>
   {/* Icône au-dessus */}
   <FaUsers
      style={{fontSize: '4rem',color: '#20c5e2',marginBottom: '1rem',}}/>
   {/* Chiffre avec "+" */}
   <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <span style={{ fontSize: '2rem', color: '#20c5e2', marginRight: '0.2rem' }}>+</span>
      <CountUp start={0} end={1257} duration={3} separator="," style={{ fontSize: '3rem',fontWeight: 'bold', color: '#002244',}}/>
   </div>
   {/* Texte en bas */}
   <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '0.5rem' }}>
      Utilisateurs actifs
   </p>
   </div>

   <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem', }}>
   {/* Icône au-dessus */}
   <FaGlobe style={{ fontSize: '4rem', color: '#20c5e2', marginBottom: '1rem', }}/>
   {/* Chiffre avec "+" */}
   <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <span style={{ fontSize: '2rem', color: '#20c5e2', marginRight: '0.2rem' }}>+</span>
      <CountUp start={0} end={6} duration={3} style={{ fontSize: '3rem', fontWeight: 'bold', color: '#002244', }}/>
   </div>
   {/* Texte en bas */}
   <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '0.5rem' }}>Pays</p>
   </div>

   <div style={{ display: 'flex',flexDirection: 'column',alignItems: 'center',padding: '2rem',}}>
   {/* Icône au-dessus */}
   <FaRegBuilding style={{fontSize: '4rem',color: '#20c5e2',marginBottom: '1rem',}}/>
   {/* Chiffre avec "+" */}
   <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <span style={{ fontSize: '2rem', color: '#20c5e2', marginRight: '0.2rem' }}>+</span>
      <CountUp start={0} end={25} duration={3} separator=","style={{fontSize: '3rem', fontWeight: 'bold', color: '#002244',}}/>
   </div>
   {/* Texte en bas */}
   <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '0.5rem' }}>Entreprises partenaires</p>
   </div>

   <div style={{ display: 'flex',flexDirection: 'column',alignItems: 'center',padding: '2rem',}}>
   {/* Icône au-dessus */}
   <FaProjectDiagram style={{fontSize: '4rem',color: '#20c5e2',marginBottom: '1rem',}}/>
   {/* Chiffre avec "+" */}
   <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <span style={{ fontSize: '2rem', color: '#20c5e2', marginRight: '0.2rem' }}>+</span>
      <CountUp start={0}end={95} duration={3} separator="," style={{ fontSize: '3rem', fontWeight: 'bold', color: '#002244',}}/>
   </div>
   {/* Texte en bas */}
   <p style={{ fontSize: '1.2rem', color: '#555', marginTop: '0.5rem' }}>Projets réalisés</p>
   </div>

</div>

{/* PARTIE AVANTAGE */}
<div className="bg-gray-50 py-20 px-6 font-raleway">
   {/* Section Avantages */}
   <h2 className="text-2xl font-bold text-center mt-16 uppercase font-roboto tracking-wide">
      Pourquoi choisir Syncro ?
   </h2>

   <p className="text-lg text-center text-gray-600 mb-10">
      Découvrez pourquoi Syncro est le meilleur choix pour la gestion de vos clients. Intuitif, flexible et accompagné d'un support 24/7.
   </p>

   <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 transition-all duration-700">
      {[
         {
            title: 'Boostez vos ventes',
            description: 'Augmentez votre taux de conversion de 25 % et réduisez le temps de vente de 40 %',
            image: require('../assets/images/trend.png'),
         },
         {
            title: 'Réactivité améliorée ',
            description: 'Prenez des décisions éclairées grâce à l’analyse en temps réel, & améliorez votre réactivité de 30 %',
            image: require('../assets/images/efficiency.png'),
         },
         {
            title: 'Fidélisez vos clients',
            description: 'Augmentez la satisfaction de vos clients de 20 % et leur rétention de 15 %',
            image: require('../assets/images/customer-experience.png'),
         },
         {
            title: 'Sécurité maximale',
            description: 'Protection des données selon des normes internationales',
            image: require('../assets/images/security.png'),
         },
         {
            title: 'Accompagnement personnalisé',
            description: 'Formation et suivi sur mesure par nos équipes',
            image: require('../assets/images/deal.png'),
         },
         {
            title: 'Flexibilité totale',
            description: 'Accédez à vos outils et données où que vous soyez ',
            image: require('../assets/images/hebergement.png'),
         },
         {
            title: 'Tarifs attractifs',
            description: 'Une solution premium adaptée à toutes les étapes de votre croissance',
            image: require('../assets/images/portefeuille.png'),
         },
         {
            title: 'Support 24/7',
            description: 'Une équipe disponible en permanence pour vous assister',
            image: require('../assets/images/service-client.png'),
         },
      ].map((advantage, index) => (
         <div
            key={index}
            className="bg-white shadow-blue-glow rounded-lg p-6 flex flex-col items-center text-center transform transition hover:scale-105"
         >
            {/* Image */}
            <div className="mb-4">
               <img
                  src={advantage.image}
                  alt={advantage.title}
                  style={{
                     width: '80px',
                     height: '80px',
                     objectFit: 'contain',
                     borderRadius: '8px',
                  }}
               />
            </div>
            {/* Couleur des titres */}
            <h3
               style={{
                  color: '#331e62',
                  fontSize: '1.25rem',
                  fontWeight: 'bold',
                  marginBottom: '0.5rem',
               }}
            >
               {advantage.title}
            </h3>
            {/* Couleur des traits */}
            <div
               className="mb-4"
               style={{
                  width: '40px',
                  height: '4px',
                  backgroundColor: '#20c5e2',
                  borderRadius: '4px',
               }}
            ></div>
            <p className="text-gray-600 text-sm">{advantage.description}</p>
         </div>
      ))}
   </div>
</div>


{/* PARTI  PARTENAIRE */}
<div className="partners-section py-16 overflow-hidden">
   <div className="text-center mb-10">
   <h2 className="text-2xl font-bold text-center mt-16 uppercase font-roboto tracking-wide">Ils nous font confiance</h2>

   </div>
   {/* Conteneur des partenaires */}
   <div className="partners-container">
  <div className="partners-row flex overflow-hidden">
    <div className="partners-track flex gap-12">
      <img src={sunubet} alt="SunuBet" className="w-1/12 h-auto" />
      <img src={atlassian} alt="Atlassian" className="w-1/12 h-auto" />
      <img src={begood} alt="BeGood" className="w-1/12 h-auto" />
      <img src={bravo} alt="Bravo" className="w-1/12 h-auto" />
      <img src={digitadvisor} alt="DigitAdvisor" className="w-1/12 h-auto" />
      <img src={kora} alt="Kora" className="w-1/12 h-auto" />
      <img src={unacois} alt="Unacois" className="w-1/12 h-auto" />
      <img src={eagles} alt="Eagles" className="w-1/12 h-auto" />
      <img src={free} alt="Free" className="w-1/12 h-auto" />
      <img src={google} alt="Google" className="w-1/12 h-auto" />
      <img src={karam} alt="Karam" className="w-1/12 h-auto" />
      <img src={kwiku} alt="Kwiku" className="w-1/12 h-auto" />
      <img src={meta} alt="Meta" className="w-1/12 h-auto" />
      <img src={microsoft} alt="Microsoft" className="w-1/12 h-auto" />
      <img src={platvoy} alt="Platvoy" className="w-1/12 h-auto" />
      <img src={slack} alt="Slack" className="w-1/12 h-auto" />
      <img src={woocommerce} alt="WooCommerce" className="w-1/12 h-auto" />
      <img src={canva} alt="Canva" className="w-1/12 h-auto" />
      <img src={zoom} alt="Zoom" className="w-1/12 h-auto" />
      {/* Répétition des images pour créer l'effet de défilement continu */}
      <img src={sunubet} alt="SunuBet" className="w-1/12 h-auto" />
      <img src={atlassian} alt="Atlassian" className="w-1/12 h-auto" />
      <img src={begood} alt="BeGood" className="w-1/12 h-auto" />
      <img src={bravo} alt="Bravo" className="w-1/12 h-auto" />
      <img src={digitadvisor} alt="DigitAdvisor" className="w-1/12 h-auto" />
      <img src={kora} alt="Kora" className="w-1/12 h-auto" />
      <img src={unacois} alt="Unacois" className="w-1/12 h-auto" />
      <img src={eagles} alt="Eagles" className="w-1/12 h-auto" />
      <img src={free} alt="Free" className="w-1/12 h-auto" />
      <img src={google} alt="Google" className="w-1/12 h-auto" />
      <img src={karam} alt="Karam" className="w-1/12 h-auto" />
      <img src={kwiku} alt="Kwiku" className="w-1/12 h-auto" />
      <img src={meta} alt="Meta" className="w-1/12 h-auto" />
      <img src={microsoft} alt="Microsoft" className="w-1/12 h-auto" />
      <img src={platvoy} alt="Platvoy" className="w-1/12 h-auto" />
      <img src={slack} alt="Slack" className="w-1/12 h-auto" />
      <img src={woocommerce} alt="WooCommerce" className="w-1/12 h-auto" />
      <img src={canva} alt="Canva" className="w-1/12 h-auto" />
      <img src={zoom} alt="Zoom" className="w-1/12 h-auto" />
    </div>
  </div>
</div>


   <style>
     {`
       .partners-container {
  width: 100%;
  overflow: hidden; /* Pour éviter le débordement des images */
}

.partners-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: nowrap; /* Empêche les éléments de passer à la ligne */
}

.partners-track {
  display: flex;
  animation: scrollLeft 40s linear infinite;
}

.partners-track img {
  width: auto;
  height: 80px; /* Ajustez la taille des images ici */
  margin-right: 20px; /* Espacement entre les images */
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* Déplace les images vers la gauche */
  }
}

@media (max-width: 768px) {
  /* Modifications pour les écrans plus petits */
  .partners-row {
    flex-wrap: wrap; /* Permet aux éléments de se réorganiser sur plusieurs lignes */
    justify-content: space-evenly; /* Aligne les éléments de manière plus uniforme */
  }

  .partners-track img {
    height: 60px; /* Réduisez la taille des images sur mobile */
    margin-right: 10px; /* Réduisez l'espacement entre les images */
  }

  header h1 {
    font-size: 2rem; /* Réduction de la taille des titres sur mobile */
  }

  header p {
    font-size: 1rem;
    padding: 0 1rem; /* Ajout de marges internes */
  }

  .intro-style {
    flex-direction: column; /* Empile les éléments sur mobile */
    gap: 1rem;
  }

  img {
    width: 100%; /* Adapte les images à la largeur de l'écran */
    height: auto;
  }

  button {
    font-size: 1rem; /* Réduction de la taille du bouton */
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  /* Ajustements supplémentaires pour les très petits écrans */
  .partners-track img {
    height: 50px; /* Réduit encore la taille des images pour les petits écrans */
    margin-right: 5px; /* Espacement encore plus réduit */
  }
}
     `}
   </style>
</div>

{/* PARTI TEMOIGNAGES  */}
<Testimonials />
<div ref={contactRef}>
        <NewContact />
</div>

{/* Footer */}
<Footer />

   

</section>
  );
};

export default Home;