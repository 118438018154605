import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FAQComponent = () => {
  const faqData = [
    { question: "Quelle est votre politique de retour ?", answer: "Nous offrons une politique de retour de 30 jours." },
    { question: "Comment puis-je suivre ma commande ?", answer: "Vous pouvez suivre votre commande à partir de votre compte." },
    { question: "Offrez-vous des réductions ?", answer: "Oui, nous offrons des réductions pour les commandes en gros." },
    { question: "Quels moyens de paiement acceptez-vous ?", answer: "Nous acceptons les cartes bancaires et les paiements PayPal." },
    { question: "Puis-je modifier ma commande ?", answer: "Vous pouvez modifier votre commande avant son expédition." },
    { question: "Comment puis-je contacter le service client ?", answer: "Vous pouvez nous contacter via le formulaire de contact ou par email." },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const container = {
    padding: "3rem",
    maxWidth: "1200px",
    margin: "0 auto",
    fontFamily: "Lato, sans-serif",
  };

  const columnContainer = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr", // Responsive: 2 colonnes par défaut
    gap: "2rem",
  };

  const itemStyle = {
    background: "#fff",
    borderRadius: "8px",
    padding: "1.5rem",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    borderBottom: "2px solid #20c5e2",
    cursor: "pointer",
    transition: "transform 0.3s ease, box-shadow 0.3s ease", // Animations
  };

  const activeItemStyle = {
    ...itemStyle,
    transform: "translateY(-5px)", // Déplace légèrement l'item actif
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)", // Ombre plus prononcée pour l'item actif
  };

  const questionStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#20c5e2",
  };

  const answerStyle = {
    paddingTop: "1rem",
    color: "#000",
    fontSize: "1rem",
    lineHeight: "1.6",
    animation: "fadeIn 0.5s ease", // Animation de réponse
  };

  return (
    <div style={container}>
      <h2 style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center", marginBottom: "2rem" }}>
        Foire Aux Questions (FAQ)
      </h2>
      <div style={columnContainer}>
        {faqData.map((faq, index) => (
          <div
            key={index}
            style={activeIndex === index ? activeItemStyle : itemStyle}
            onClick={() => toggleQuestion(index)}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h3 style={questionStyle}>{faq.question}</h3>
              {activeIndex === index ? <FaChevronUp color="#20c5e2" /> : <FaChevronDown color="#20c5e2" />}
            </div>
            {activeIndex === index && <p style={answerStyle}>{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
