import React, { useState } from 'react';
import backgroundImage from "../assets/images/backPricing.jpg";
import jambarImage from '../assets/images/jambar.png';
import mansaImage from '../assets/images/mansa.png';
import surDevisImage from '../assets/images/petitlogo.png';
import ComparisonTable from '../components/Common/ComparisonTable';
import FAQ from '../components/Common/FAQ';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';

const Pricing = () => {
  const [jambarBilling, setJambarBilling] = useState('monthly');
  const [otherPacksBilling, setOtherPacksBilling] = useState('semiAnnual');

  const toggleJambarBilling = () =>
    setJambarBilling((prev) => (prev === 'monthly' ? 'quarterly' : 'monthly'));

  const toggleOtherPacksBilling = () =>
    setOtherPacksBilling((prev) => (prev === 'semiAnnual' ? 'annual' : 'semiAnnual'));

  const [selectedPack, setSelectedPack] = useState(null); // État pour le pack sélectionné
  const openModal = (packName) => {
    setSelectedPack(packName); // Définit le pack sélectionné
  };
  
  const closeModal = () => {
    setSelectedPack(null); // Réinitialise le pack sélectionné
  };


  const backgroundSectionStyle = {
    position: 'relative',
    height: '60vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '80px',
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  };

  const textContainerStyle = {
    position: 'relative',
    zIndex: 2,
    fontFamily: 'Lato, sans-serif',
  };

  const pageStyle = {
    padding: '4rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3rem',
    fontFamily: 'Lato, sans-serif',
  };

  const sectionStyle = {
    padding: '2rem',
    textAlign: 'center',
  };

  const planContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    flexWrap: 'wrap',
    marginTop: '2rem',
  };

  const planCardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '2.5rem',
    borderRadius: '10px',
    boxShadow: '0 4px 15px rgba(78, 177, 226, 0.3)',
    width: '320px',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    transition: 'transform 0.3s ease',
  };

  const headerStyle = (color) => ({
    backgroundColor: color,
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '0.5rem 1rem',
    borderRadius: '5px',
    position: 'absolute',
    top: '-20px',
    left: '10px',
    fontSize: '1.3rem',
  });

  const imageStyle = {
    width: '80px',
    height: '80px',
    objectFit: 'contain',
    margin: '0 auto',
    marginBottom: '1rem',
  };

  const taglineStyle = {
    fontWeight: 'bold',
    marginBottom: '1rem',
  };

  const detailsStyle = {
    textAlign: 'center',
    color: '#FF9E00',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: '1.5rem',
  };

  const priceBoxStyle = {
    backgroundColor: '#20c5e2',
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '1rem',
    borderRadius: '8px',
    fontSize: '1.5rem',
    marginTop: '1rem',
  };

  const priceUnitStyle = {
    fontSize: '0.8rem',
    display: 'block',
    marginTop: '0.5rem',
  };


  const switchStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#002244',
    width: '180px',
    height: '40px',
    borderRadius: '20px',
    cursor: 'pointer',
    position: 'relative',
    margin: '1rem auto',
  };

  const toggleCircleStyle = (billing) => ({
    width: '90px',
    height: '25px',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    position: 'absolute',
    left: billing === 'monthly' || billing === 'semiAnnual' ? '5px' : 'calc(100% - 95px)',
    transition: 'left 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: '#002244',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  });

  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };
  
  const modalContentStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: '2rem',
    borderRadius: '10px',
    maxWidth: '800px',
    width: '90%',
    textAlign: 'center',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    position: 'relative',
  };
  
  const inputGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    width: '100%',
  };
   
  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    margin: '0 auto',
  };
    
  const labelStyle = {
    color: 'white',
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '0.5rem',
  };
  
  const inputStyle = {
    width: '100%',
    padding: '0.75rem',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '2px solid #4eb1e1',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    marginBottom: '1rem'
  };
  
  const iconStyle = {
    color: 'white',
    marginRight: '0.5rem',
  };
  const textareaStyle = {
    ...inputStyle,
    height: '100px',
    resize: 'none',
    gridColumn: '1 / -1',
  };   
  
  const submitButtonStyle = {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#4eb1e1',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    gridColumn: '1 / -1',
    marginTop: '1rem',
  };

const arrierePlan = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const contenu = {
  width: '80%',
  maxWidth: '1200px',
  height: 'auto',
  maxHeight: '85vh',
  overflowY: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '10px',
  padding: '20px',
  position: 'relative',
};

const champ = {
  width: '100%',
  padding: '1.5rem',
  fontSize: '1rem',
  borderRadius: '5px',
  border: '1px solid #1e90ff',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: 'white',
};


const cadre = {
  border: '1px solid #1e90ff',
  borderRadius: '5px',
  padding: '1rem',
  marginTop: '3rem',
  color: 'white',
};

const legende = {
  fontWeight: 'bold',
  fontSize: '1rem',
  color: 'white',
};

const texteArea = {
  width: '100%',
  height: '100px',
  padding: '0.75rem',
  fontSize: '1rem',
  borderRadius: '5px',
  border: '1px solid #1e90ff',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: 'white',
  resize: 'vertical',
};

const envoyer = {
  padding: '0.75rem 1.5rem',
  backgroundColor: '#1e90ff',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '1rem',
  fontWeight: 'bold',
  alignSelf: 'center',
};
  
const plans = [
  {
    name: 'JAMBAAR',
    image: mansaImage,
    tagline: 'Idéal pour les TPE et les starters',
    details: 
    [ 
      '- Agenda', 
      '- Leads', 
      '- Clients', 
      '- Devis', 
      '- Facture', 
      '- Taches', 
      '- Syncro Mail', 
      '- Rapport' 
    ],
    priceSemiAnnual: '10.000',
    priceAnnually: '7.000',
    users: '',
    color: '#5ce1e1',
    button: (openModal) => (
      <button
        style={{
          background: 'linear-gradient(45deg,#331e62, #20c5e2)',
          color: '#ffffff',
          padding: '0.75rem 1.5rem',
          borderRadius: '30px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.3rem',
          marginTop: '1.5rem',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'transform 0.3s ease',
          alignSelf: 'center',
        }}
        onClick={() => openModal('JAMBAR')}
      >
        Demander un devis
      </button>
    ),
  },
  {
    name: 'JAMBAAR +',
    image: jambarImage,
    tagline: 'Votre allié de croissance',
    details: 
    [
      'Tout Jambaar en plus de:', 
      '- Objectifs', 
      '- Note de credit', 
      '- Collaborateur', 
      '- Syncro Drive', 
      '- Rapport avances', 
      '- Live chat', 
      '- Support'
    ],
    priceSemiAnnual: '30.000',
    priceAnnually: '25.000',
    users: '',
    color: '#858ae3',
    button: (openModal) => (
      <button
        style={{
          background: 'linear-gradient(45deg,#331e62, #20c5e2)',
          color: '#ffffff',
          padding: '0.75rem 1.5rem',
          borderRadius: '30px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.3rem',
          marginTop: '1.5rem',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'transform 0.3s ease',
          alignSelf: 'center',
        }}
        onClick={() => openModal('MANSA')}
      >
        Demander un devis
      </button>
    ),
  },

  {
    name: 'SUR DEVIS',
    image: surDevisImage,
    tagline: 'Contactez-nous pour vos besoins sur mesure',
    details: 
    [
      '- Audit approfondie',
      '- Personnalisation complete',
      '- Integration avancee',
      '- Migration de vos donnees',
      '- Support dedie',
      '- Evolutivite',
      '- Minimum 10 Utilisateurs *',
    ],
    priceSemiAnnual: 'À définir',
    priceAnnually: 'À définir',
    color: '#ff5733',
    button: (openModal) => (
      <button
        style={{
          background: 'linear-gradient(45deg, #ff5733, #ff8c00)',
          color: '#ffffff',
          padding: '0.75rem 1.5rem',
          borderRadius: '30px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.3rem',
          marginTop: '1.5rem',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'transform 0.3s ease',
          alignSelf: 'center',
        }}
        onClick={() => openModal('SUR DEVIS')}
      >
        Demander un devis
      </button>
    ),
  }
];


  return (
    <>
      <Header />
      <div style={backgroundSectionStyle}>
        <div style={overlayStyle}></div>
        <div style={textContainerStyle}>
          <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>
            Trouvez l'offre qui vous correspond
          </h1>
          <p style={{ fontSize: '1.5rem', margin: '0 auto', maxWidth: '700px' }}>
            Nos packs sont adaptés à vos besoins mais aussi à votre budget quelque soit votre secteur !
          </p>
        </div>
      </div>

      <div style={pageStyle}>
        <section style={sectionStyle}>
        <h2 className="text-3xl font-bold mb-4 text-center uppercase tracking-wide font-roboto text-[black]">NOS OFFRES</h2>

          <br></br>

          <div style={switchStyle} onClick={toggleOtherPacksBilling}>
            <div style={toggleCircleStyle(otherPacksBilling)}>
              {otherPacksBilling === 'semiAnnual' ? 'Trimestriel' : 'Annuel'}
            </div>
          </div>

          {/* Conteneur des plans */}
          <div style={planContainerStyle}>
            {plans.map((plan, index) => (
              <div key={index} style={planCardStyle}>
                <img src={plan.image} alt={plan.name} style={imageStyle} />
                <div style={headerStyle(plan.color)}>{`PACK ${plan.name}`}</div>
                <p style={taglineStyle}>{plan.tagline}</p>
                <ul style={detailsStyle}>
                  {plan.details.map((detail, idx) => (
                    <li key={idx}>{detail}</li>
                  ))}
                </ul>
                <div style={priceBoxStyle}>
                  {plan.name === 'JAMBAR'
                    ? jambarBilling === 'monthly'
                      ? `${plan.priceMonthly}`
                      : `${plan.priceQuarterly}`
                    : otherPacksBilling === 'semiAnnual'
                    ? `${plan.priceSemiAnnual}`
                    : `${plan.priceAnnually}`}
                  <span style={{ fontSize: '0.8rem'}}> F.CFA</span>
                  <span style={priceUnitStyle}>/Utilisateur</span>
                </div>
                <small style={{ marginTop: '2rem', display: 'block' }}>{plan.users}</small>

                {/* Bouton JAMBAR : Mensuel/Trimestriel */}
                {plan.name === 'JAMBAR' && (
                  <div style={switchStyle} onClick={toggleJambarBilling}>
                    <div style={toggleCircleStyle(jambarBilling)}>
                      {jambarBilling === 'monthly' ? 'Mensuel' : 'Trimestriel'}
                    </div>
                  </div>
                )}

                {typeof plan.button === 'function' && plan.button(openModal)}
              </div>
            ))}

          </div>
        </section>
      </div>

{/* Formulaire pour les 3 packs */}
{selectedPack && selectedPack !== 'SUR DEVIS' && (
  <div style={modalOverlayStyle}>
    <div
      style={{
        ...modalContentStyle,
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '1.5rem',
        position: 'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }}
    >
      <button
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: '#f44336',
          border: 'none',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: 'white',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        ×
      </button>
      <img
        src={plans.find((plan) => plan.name === selectedPack).image}
        alt={selectedPack}
        style={{
          width: '100px',
          height: '100px',
          margin: '0 auto',
          display: 'block',
        }}
      />
      <h2
        style={{
          color: 'white',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          margin: '1rem 0',
          textAlign: 'center',
        }}
      >
        Formulaire pour le pack {selectedPack}
      </h2>
      <p
        style={{
          color: 'white',
          fontWeight: 'bold',
          marginBottom: '1rem',
          textAlign: 'center',
        }}
      >
        Remplissez le formulaire ci-dessous pour commander le pack <strong>{selectedPack}</strong>.
      </p>

      {/* Formulaire */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const utilisateurs = parseInt(e.target.users.value, 10);
          if (
            (selectedPack === 'JAMBAR' && utilisateurs > 5) ||
            (selectedPack === 'MANSA' && utilisateurs > 15) ||
            (selectedPack === 'BAOBAB' && utilisateurs > 25)
          ) {
            alert(
              `Le pack ${selectedPack} ne peut pas supporter ${utilisateurs} utilisateurs. Nous vous recommandons un autre pack.`
            );
          } else {
            console.log(`Formulaire soumis pour le pack ${selectedPack}`);
            closeModal();
          }
        }}
        style={formStyle}
      >
        <div style={inputGridStyle}>
          <div>
            <label style={labelStyle}>
              <i className="fas fa-user" style={iconStyle}></i>
              <input type="text" name="name" placeholder="Votre nom" required style={inputStyle} />
            </label>
            <label style={labelStyle}>
              <i className="fas fa-envelope" style={iconStyle}></i>
              <input type="email" name="email" placeholder="Votre email" required style={inputStyle} />
            </label>
            <label style={labelStyle}>
              <i className="fas fa-users" style={iconStyle}></i>
              <input
                type="number"
                name="users"
                placeholder="Nombre d'utilisateurs"
                required
                style={inputStyle}
                min="1"
              />
            </label>
          </div>

          <div>
            <label style={labelStyle}>
              <i className="fas fa-building" style={iconStyle}></i>
              <input
                type="text"
                name="company"
                placeholder="Nom de votre entreprise"
                required
                style={inputStyle}
              />
            </label>
            <label style={labelStyle}>
              <i className="fas fa-phone" style={iconStyle}></i>
              <input
                type="tel"
                name="phone"
                placeholder="Votre téléphone"
                required
                style={inputStyle}
              />
            </label>
            <label style={labelStyle}>
              <i className="fas fa-map-marker-alt" style={iconStyle}></i>
              <input
                type="text"
                name="address"
                placeholder="Votre adresse"
                required
                style={inputStyle}
              />
            </label>
          </div>
        </div>

        <label style={labelStyle}>
          <i className="fas fa-info-circle" style={iconStyle}></i>
          <textarea
            name="details"
            placeholder="Ajoutez des détails supplémentaires (facultatif)"
            style={textareaStyle}
          />
        </label>

        <button type="submit" style={submitButtonStyle}>
          Envoyer
        </button>
      </form>
    </div>
  </div>
)}

{/* Formulaire pour le pack "Sur Devis", en dehors du div */}
{selectedPack === 'SUR DEVIS' && (
  <div style={arrierePlan}>
    <div style={contenu}>
      <button
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: '#f44336',
          border: 'none',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: 'white',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        ×
      </button>

      <img
        src={surDevisImage}
        alt="Pack Sur Devis"
        style={{
          width: '100px',
          height: '100px',
          margin: '0 auto',
          display: 'block',
        }}
      />

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        margin: '1rem 0',
      }}>
        <h2 style={{
          color: 'white',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          marginBottom: '1rem',
        }}>
          Formulaire pour le pack sur-mesure
        </h2>
        <p style={{
          color: 'white',
          fontWeight: 'bold',
          marginBottom: '1rem',
        }}>
          Remplissez le formulaire ci-dessous pour obtenir un devis personnalisé.
        </p>
      </div>


      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log('Formulaire soumis pour le pack sur-mesure');
          closeModal();
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        {/* Grille des champs d'entrée */}
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', flexWrap: 'wrap' }}>
          <div style={{ flex: '1', minWidth: '300px' }}>
            <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
              <input type="text" name="name" placeholder="Votre nom" required style={champ} />
            </label>
            <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
              <input type="email" name="email" placeholder="Votre email" required style={champ} />
            </label>
            <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
              <input
                type="number"
                name="users"
                placeholder="Nombre d'utilisateurs"
                required
                style={champ}
                min="1"
              />
            </label>
          </div>
          <div style={{ flex: '1', minWidth: '300px' }}>
            <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
              <input type="text" name="company" placeholder="Nom de votre entreprise" required style={champ} />
            </label>
            <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
              <input type="tel" name="phone" placeholder="Votre téléphone" required style={champ} />
            </label>
            <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
              <input type="text" name="address" placeholder="Votre adresse" required style={champ} />
            </label>
          </div>
        </div>

        {/* Sections avec des options */}
        <fieldset style={cadre}>
          <legend style={legende}>Flexibilité et Personnalisation</legend>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <label>
              <input type="checkbox" name="custom_features" /> Choix des fonctionnalités (CRM, gestion de projets, etc.)
            </label>
            <label>
              <input type="checkbox" name="custom_integrations" /> Intégrations spécifiques (ex : API, outils de paiement)
            </label>
            <label>
              <input type="checkbox" name="multilingual_support" /> Support multilingue
            </label>
            <label>
              <input type="checkbox" name="custom_interface" /> Interface personnalisée (logos, couleurs, etc.)
            </label>
          </div>
        </fieldset>

        <fieldset style={cadre}>
          <legend style={legende}>Services Avancés</legend>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <label>
              <input type="checkbox" name="training" /> Formation dédiée pour les équipes
            </label>
            <label>
              <input type="checkbox" name="priority_support" /> Support technique prioritaire
            </label>
            <label>
              <input type="checkbox" name="onsite_deployment" /> Déploiement sur site
            </label>
          </div>
        </fieldset>

        <fieldset style={cadre}>
          <legend style={legende}>Fonctionnalités Spécifiques</legend>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <label>
              <input type="checkbox" name="advanced_reports" /> Rapports et analytics avancés
            </label>
            <label>
              <input type="checkbox" name="multi_sites" /> Gestion multi-sites
            </label>
            <label>
              <input type="checkbox" name="enhanced_security" /> Sécurité renforcée
            </label>
          </div>
        </fieldset>

        <label style={{ display: 'block', marginBottom: '1rem', color: '#ffffff', fontWeight: 'bold' }}>
          Détails :
          <textarea
            name="details"
            placeholder="Ajoutez des détails supplémentaires (facultatif)"
            style={texteArea}
          />
        </label>

        <button type="submit" style={envoyer}>
          Envoyer
        </button>
      </form>
    </div>
  </div>
)}

      <ComparisonTable />
      <FAQ/>
      <Footer />
    </>
  );
};

export default Pricing;
