import React from "react";
import { FaArrowRight, FaCircle } from "react-icons/fa";

// Styles principaux avec responsivité
const sectionStyle = {
  padding: "4rem 2rem",
  display: "grid",
  gridTemplateColumns: "1fr 4px 1fr", // Colonnes : Missions | Séparateur | Engagements
  alignItems: "start",
  justifyItems: "center",
  backgroundColor: "#ffffff",
  gap: "2rem",
};

// Styles pour les colonnes
const columnStyle = {
  maxWidth: "600px",
  minWidth: "300px",
};

const titleStyle = {
  fontSize: "2rem",
  fontWeight: "bold",
  color: "black",
  marginBottom: "1rem",
  fontFamily: "Raleway, sans-serif",
};

const paragraphContainerStyle = {
  display: "flex",
  alignItems: "flex-start",
  gap: "0.5rem",
  marginBottom: "1.5rem",
};

const iconStyle = {
  fontSize: "1rem", // Taille fixe des icônes
  width: "1rem", // Largeur fixe
  height: "1rem", // Hauteur fixe
  color: "#20c5e2",
  flexShrink: 0, // Empêche l'icône de rétrécir
};

const textStyle = {
  fontSize: "1.1rem",
  color: "#000",
  lineHeight: "1.8",
  fontFamily: "Lato, sans-serif",
  textAlign: "justify",
};

const dividerVerticalStyle = {
  width: "4px",
  backgroundColor: "#4eb1e2",
  minHeight: "100%",
  alignSelf: "stretch", // S'étend pour aligner avec la hauteur des colonnes
};

// Ajout de media queries
const responsiveStyles = `
  @media (max-width: 768px) {
    .vision-section {
      grid-template-columns: 1fr; /* Colonnes empilées */
      gap: 1rem;
    }

    .vision-divider {
      display: none; /* Cache le séparateur sur petits écrans */
    }
  }
`;

const VisionSection = () => {
  return (
    <>
      {/* Styles responsifs intégrés */}
      <style>{responsiveStyles}</style>
      <section className="vision-section" style={sectionStyle}>
        {/* Première colonne - Nos Missions */}
        <div style={{ ...columnStyle, textAlign: "left" }}>
          <h2 style={titleStyle}>Notre Mission</h2>
          <div style={paragraphContainerStyle}>
            <FaArrowRight style={iconStyle} />
            <p style={textStyle}>
              Réinventer la gestion d’entreprise avec une plateforme CRM simple,
              puissante et sécurisée, conçue pour répondre aux besoins
              spécifiques de chaque entreprise, quelle que soit sa taille ou son
              secteur.
            </p>
          </div>
          <div style={paragraphContainerStyle}>
            <FaArrowRight style={iconStyle} />
            <p style={textStyle}>
              Grâce à une technologie avancée et innovante, nous nous engageons
              à fournir des solutions permettant à nos clients de centraliser
              leurs données, d’automatiser leurs processus et de booster leur
              productivité.
            </p>
          </div>
          <div style={paragraphContainerStyle}>
            <FaArrowRight style={iconStyle} />
            <p style={textStyle}>
              Nous croyons en un avenir où chaque entreprise peut se concentrer
              sur l’essentiel, en toute sérénité, sachant que ses processus sont
              entre de bonnes mains.
            </p>
          </div>
        </div>

        {/* Séparateur vertical */}
        <div className="vision-divider" style={dividerVerticalStyle}></div>

        {/* Deuxième colonne - Notre Engagement */}
        <div style={{ ...columnStyle, textAlign: "left" }}>
          <h2 style={titleStyle}>Notre Engagement</h2>
          <div style={paragraphContainerStyle}>
            <FaCircle style={iconStyle} />
            <p style={textStyle}>
              Chez Syncro, la sécurité de vos données est notre priorité. C’est
              pourquoi nos solutions respectent des normes de protection de
              classe mondiale.
            </p>
          </div>
          <div style={paragraphContainerStyle}>
            <FaCircle style={iconStyle} />
            <p style={textStyle}>
              Nous nous engageons à vous offrir un produit de qualité, conçu
              pour répondre à vos besoins actuels et évoluer avec vous. Votre
              satisfaction est au cœur de notre mission, et nous vous
              accompagnons à chaque étape pour vous guider vers l'excellence.
            </p>
          </div>
          <div style={paragraphContainerStyle}>
            <FaCircle style={iconStyle} />
            <p style={textStyle}>
              Avec Syncro, atteignez vos objectifs en toute confiance.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisionSection;
