import React, { useRef, useState } from "react";
import videoSrc from "../../assets/images/demovideo.mp4";

const DemoVideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = false; // Active le son
      video.play(); // Joue la vidéo
      setIsPlaying(true); // Cache le texte et le bouton
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false); // Réaffiche le texte et le bouton à la fin de la vidéo
  };

  const sectionStyle = {
    position: "relative",
    width: "100%", // Prend toute la largeur de son conteneur parent
    height: "100%", // Prend toute la hauteur de son conteneur parent
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: isPlaying ? "transparent" : "rgba(0, 0, 0, 0.7)", // Opacité si la vidéo n'est pas jouée
    zIndex: 2,
    display: isPlaying ? "none" : "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  };

  const titleStyle = {
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1.5rem",
  };

  const playButtonStyle = {
    padding: "10px 30px",
    fontSize: "1.2rem",
    color: "#fff",
    border: "2px solid #feb91c",
    backgroundColor: "transparent",
    borderRadius: "50px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    display: isPlaying ? "none" : "inline-block",
  };

  const videoStyle = {
    width: "100%", // Ajuste la vidéo à la largeur de son conteneur
    height: "100%", // Ajuste la vidéo à la hauteur de son conteneur
    objectFit: "cover", // Ajuste le contenu pour éviter les bordures
    borderRadius: "inherit", // Respecte les coins arrondis du conteneur parent
  };

  return (
    <div style={sectionStyle}>
      <div style={overlayStyle}>
        <h2 style={titleStyle}>EXPLOREZ SYNCRO</h2>
        <button style={playButtonStyle} onClick={handlePlayVideo}>
          ▶ Play
        </button>
      </div>

      <video
        ref={videoRef}
        style={videoStyle}
        src={videoSrc}
        type="video/mp4"
        controls
        onEnded={handleVideoEnded}
      >
        Votre navigateur ne supporte pas la lecture vidéo.
      </video>
    </div>
  );
};

export default DemoVideoSection;
