import React, { useEffect, useState } from "react";
import { FaQuoteLeft, FaUser } from "react-icons/fa";

const Testimonials = () => {
  const testimonials = [
    { text: "SiteSyncro a révolutionné notre gestion client. Simple et efficace !", name: "Julie P.", role: "Client" },
    { text: "Une interface intuitive et un support au top, je recommande !", name: "David L.", role: "Client" },
    { text: "Une excellente solution pour la gestion de nos relations clients.", name: "Marie K.", role: "Client" },
    { text: "La meilleure plateforme CRM que nous ayons utilisée.", name: "Jean M.", role: "Client" },
    { text: "Support technique rapide et efficace, bravo !", name: "Emma W.", role: "Client" },
    { text: "Une expérience utilisateur sans précédent.", name: "Lucas B.", role: "Client" },
    { text: "Des fonctionnalités innovantes, exactement ce dont on avait besoin.", name: "Sophia H.", role: "Client" },
    { text: "Une équipe réactive et un produit performant, je recommande.", name: "Noah R.", role: "Client" },
  ];

  const [visibleTestimonials, setVisibleTestimonials] = useState([0, 1]);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleTestimonials(([first, second]) => {
        const nextFirst = (first + 2) % testimonials.length;
        const nextSecond = (second + 2) % testimonials.length;
        return [nextFirst, nextSecond];
      });
    }, 5000);

    return () => clearInterval(interval); // Nettoyage à la fin
  }, [testimonials.length]);

  return (
    <div style={{ padding: "5rem 2rem", textAlign: "center", background: "white" }}>
<h2 className="text-2xl font-bold text-center mb-8 uppercase font-roboto tracking-wide">
  Ce que disent nos clients
</h2>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", // Responsive grid
          gap: "2rem",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        {visibleTestimonials.map((index) => {
          const { text, name, role } = testimonials[index];
          return (
            <div
              key={index}
              style={{
                position: "relative",
                padding: "2rem",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow:
                  "0 6px 15px rgba(32, 197, 226, 0.3), 0 4px 12px rgba(32, 197, 226, 0.3)", // Ombre bleu transparent
                textAlign: "left",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              <FaQuoteLeft
                style={{
                  fontSize: "2rem",
                  color: "#20c5e2",
                  position: "absolute",
                  top: "-15px",
                  left: "-15px",
                }}
              />
              <p
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "1.2rem",
                  color: "#555",
                  lineHeight: "1.6",
                }}
              >
                {`"${text}"`}
              </p>
              <div style={{ display: "flex", alignItems: "center", marginTop: "1.5rem" }}>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#20c5e2",
                    fontSize: "1.5rem",
                    marginRight: "0.5rem",
                    borderRadius: "50%",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <FaUser />
                </div>
                <div>
                  <span
                    style={{
                      fontFamily: "Nunito, sans-serif",
                      color: "#333",
                      fontWeight: "bold",
                    }}
                  >
                    {name}
                  </span>{" "}
                  - {role}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* Indicateurs */}
      <div style={{ marginTop: "2rem", display: "flex", justifyContent: "center", gap: "0.5rem" }}>
        {Array.from({ length: testimonials.length / 2 }).map((_, i) => (
          <div
            key={i}
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: visibleTestimonials[0] / 2 === i ? "#20c5e2" : "#ccc",
              transition: "background-color 0.3s ease",
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
