import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import BackgroundImage from "../assets/images/back.png";
import VideoThumbnail from "../assets/images/imageapp.png";
import FAQ from "../components/Common/FAQ";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";
import NewContact from "../components/Common/NewContact";

const Ressource = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const blogPosts = [
    {
      id: 1,
      title: "Améliorez votre productivité avec notre ERP",
      date: "1 Décembre 2024",
      content:
        "Découvrez comment notre solution ERP peut transformer votre entreprise en optimisant vos processus et en améliorant votre efficacité. Une gestion simplifiée, des outils puissants et une approche intuitive.",
    },
    {
      id: 2,
      title: "5 Raisons d'adopter Syncro ERP",
      date: "20 Novembre 2024",
      content:
        "Syncro ERP est conçu pour simplifier la gestion d'entreprise. Découvrez ses fonctionnalités clés et pourquoi il est le choix idéal pour votre entreprise. Nos outils sont spécialement développés pour augmenter votre productivité et réduire vos coûts.",
    },
    {
      id: 3,
      title: "Guide complet pour les petites entreprises",
      date: "10 Novembre 2024",
      content:
        "Apprenez à gérer efficacement vos ressources, vos équipes et vos finances avec notre guide spécialement conçu pour les petites entreprises. Nous fournissons des solutions adaptées à vos besoins, même pour les budgets serrés.",
    },
  ];

  const openModal = (article) => {
    setSelectedArticle(article);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedArticle(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />

      {/* Section 1: Demander une démo */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "70vh",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            color: "#fff",
          }}
        >
          <h1
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Pourquoi demander une démo ?
          </h1>
          <p
            style={{
              fontSize: "1.3rem",
              maxWidth: "700px",
              margin: "0 auto",
              lineHeight: "1.8",
            }}
          >
            Découvrez comment notre solution ERP peut transformer votre gestion
            d'entreprise. Une démonstration vous permettra de comprendre ses
            fonctionnalités et son impact sur vos processus.
          </p>
          <button
            style={{
              marginTop: "1.5rem",
              padding: "12px 25px",
              backgroundColor: "#20c5e2",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              fontSize: "1.2rem",
              cursor: "pointer",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            Demander une démo
          </button>
        </div>
      </div>

      {/* Section 2: Vidéo de démonstration */}
      <div
        style={{
          padding: "4rem 3rem",
          textAlign: "center",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h2
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#20c5e2",
            marginBottom: "2rem",
          }}
        >
          Vidéo de démonstration
        </h2>
        <div
          style={{
            position: "relative",
            maxWidth: "800px",
            margin: "0 auto",
            cursor: "pointer",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => setIsVideoPlaying(true)}
        >
          {isVideoPlaying ? (
            <video
              controls
              autoPlay
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <source
                src="https://www.w3schools.com/html/mov_bbb.mp4"
                type="video/mp4"
              />
              Votre navigateur ne supporte pas la vidéo.
            </video>
          ) : (
            <>
              <img
                src={VideoThumbnail}
                alt="Vidéo de démonstration"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  borderRadius: "50%",
                  width: "70px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaPlay style={{ color: "#fff", fontSize: "28px" }} />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Section 3: Blog */}
      <div style={{ padding: "3rem 3rem" }}>
        <h2
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#20c5e2",
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          Notre Blog
        </h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "2rem",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {blogPosts.map((post) => (
            <div
              key={post.id}
              style={{
                backgroundColor: "#fff",
                padding: "1.5rem",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "left",
              }}
            >
              <h3
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#20c5e2",
                  marginBottom: "0.5rem",
                }}
              >
                {post.title}
              </h3>
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#888",
                  marginBottom: "1rem",
                }}
              >
                {post.date}
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  color: "#333",
                  lineHeight: "1.6",
                }}
              >
                {post.content.slice(0, 100)}...
              </p>
              <button
                onClick={() => openModal(post)}
                style={{
                  marginTop: "1rem",
                  padding: "8px 15px",
                  backgroundColor: "#20c5e2",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
              >
                Lire l'article
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Modal pour l'article */}
      {isModalOpen && selectedArticle && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "2rem",
              borderRadius: "10px",
              width: "80%",
              maxWidth: "800px",
              position: "relative",
              textAlign: "left",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3
              style={{
                fontSize: "1.8rem",
                fontWeight: "bold",
                color: "#20c5e2",
                marginBottom: "1rem",
              }}
            >
              {selectedArticle.title}
            </h3>
            <p
              style={{
                fontSize: "0.9rem",
                color: "#888",
                marginBottom: "1rem",
              }}
            >
              {selectedArticle.date}
            </p>
            <p
              style={{
                fontSize: "1rem",
                color: "#333",
                lineHeight: "1.8",
              }}
            >
              {selectedArticle.content}
            </p>
            <button
              onClick={closeModal}
              style={{
                marginTop: "1.5rem",
                padding: "10px 20px",
                backgroundColor: "#20c5e2",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                fontSize: "1rem",
                cursor: "pointer",
              }}
            >
              Fermer
            </button>
          </div>
        </div>
      )}

      {/* Section 4: FAQ */}
      <div style={{ padding: "4rem 3rem", backgroundColor: "#f9f9f9" }}>
        <h2
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#20c5e2",
            textAlign: "center",
            marginBottom: "2rem",
          }}
        >
          FAQ
        </h2>
        <FAQ />
      </div>

      <NewContact/>
      <Footer />
    </div>
  );
};

export default Ressource;
