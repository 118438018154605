import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  FaBriefcase,
  FaBuilding,
  FaCalendarAlt,
  FaClipboardList,
  FaEnvelope,
  FaPhoneAlt,
  FaUser,
} from "react-icons/fa";
import backgroundImage from "../../assets/images/bgcontact.png";

const ContactSection = () => {
  const [activeForm, setActiveForm] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    jobTitle: '',
    companyName: '',
    industry: '',
    companySize: '',
    crmUsage: '',
    phone: '',
    email: '',
    packChoice: '',
    demoDate: '',
    message: ''
  });

  const toggleForm = (formType) => {
    setActiveForm(activeForm === formType ? null : formType);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(formData);
  };

  const sendEmail = (data) => {
    emailjs
      .send(
        'service_p016bap', // ID de service
        'template_8ucvq3m', // ID de modèle
        data,
        'uP9q4J77304_IIFLU' // ID utilisateur
      )
      .then(
        (response) => {
          alert('Message envoyé avec succès !');
        },
        (error) => {
          alert('Erreur lors de l\'envoi de l\'email : ', error);
        }
      );
  };

  const iconStyle = {
    position: "absolute",
    top: "50%",
    left: "1rem",
    transform: "translateY(-50%)",
    color: "#fff",
    fontSize: "1.5rem",
  };

  const containerStyle = {
    position: "relative",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    minHeight: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    color: "#fff",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  };

  const contentStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "700px",
    width: "100%",
    zIndex: 2,
  };

  const buttonStyle = {
    padding: "1rem 2rem",
    backgroundColor: "#20c5e2",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    fontSize: "1.2rem",
    fontWeight: "bold",
    cursor: "pointer",
    margin: "1rem 0",
    transition: "background-color 0.3s ease",
  };

  const formStyle = {
    background: "rgba(0, 0, 0, 0.8)",
    padding: "1.5rem",
    borderRadius: "8px",
    maxWidth: "600px",
    width: "100%",
    maxHeight: "65vh",
    overflowY: "auto",
    zIndex: 3,
  };

  const inputContainerStyle = {
    position: "relative",
    marginBottom: "1rem",
    width: "45%",
  };

  const inputStyle = {
    width: "100%",
    padding: "0.8rem 0.8rem 0.8rem 2.5rem",
    border: "2px solid #20c5e2",
    borderRadius: "6px",
    background: "transparent",
    color: "#fff",
    fontSize: "1.1rem",
  };

  const selectStyle = {
    ...inputStyle,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    appearance: "none",
  };

  const fieldsContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "1rem",
  };

  const renderForm = () => {
    if (!activeForm) return null;

    const formTitle = activeForm === "demo" ? "Demander une Démo" : "Contactez-nous";

    const formFields = activeForm === "demo" ? (
      <>
        {/* Form fields for demo request */}
        <div style={fieldsContainerStyle}>
          <div style={inputContainerStyle}>
            <FaUser style={iconStyle} />
            <input
              type="text"
              name="name"
              placeholder="Nom complet"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaBriefcase style={iconStyle} />
            <input
              type="text"
              name="jobTitle"
              placeholder="Poste occupé"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaBuilding style={iconStyle} />
            <input
              type="text"
              name="companyName"
              placeholder="Nom de l'entreprise"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaClipboardList style={iconStyle} />
            <input
              type="text"
              name="industry"
              placeholder="Secteur d'activité"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaClipboardList style={iconStyle} />
            <select name="companySize" style={selectStyle} onChange={handleChange}>
              <option value="">Nombre d'employés</option>
              <option value="1-5">1-5 employés</option>
              <option value="6-15">6-15 employés</option>
              <option value="16-50">16-50 employés</option>
              <option value="51-100">51-100 employés</option>
              <option value="101-200">101-200 employés</option>
              <option value="200+">200+ employés</option>
            </select>
          </div>
          <div style={inputContainerStyle}>
            <FaPhoneAlt style={iconStyle} />
            <input
              type="tel"
              name="phone"
              placeholder="Téléphone"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaEnvelope style={iconStyle} />
            <input
              type="email"
              name="email"
              placeholder="Email"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaCalendarAlt style={iconStyle} />
            <input
              type="date"
              name="demoDate"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        {/* Form fields for contact */}
        <div style={fieldsContainerStyle}>
          <div style={inputContainerStyle}>
            <FaUser style={iconStyle} />
            <input
              type="text"
              name="name"
              placeholder="Nom complet"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaBuilding style={iconStyle} />
            <input
              type="text"
              name="companyName"
              placeholder="Nom de l'entreprise"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaPhoneAlt style={iconStyle} />
            <input
              type="tel"
              name="phone"
              placeholder="Téléphone"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
          <div style={inputContainerStyle}>
            <FaEnvelope style={iconStyle} />
            <input
              type="email"
              name="email"
              placeholder="Email"
              style={inputStyle}
              onChange={handleChange}
            />
          </div>
        </div>
        <textarea
          name="message"
          placeholder="Votre Message"
          style={{
            ...inputStyle,
            height: "100px",
            resize: "none",
            width: "100%",
          }}
          onChange={handleChange}
        ></textarea>
      </>
    );

    return (
      <div style={formStyle}>
        <h2 style={{ color: "#fff", textAlign: "center", marginBottom: "1.5rem" }}>
          {formTitle}
        </h2>
        <form onSubmit={handleSubmit}>
          {formFields}
          <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
            <button type="submit" style={buttonStyle}>
              Envoyer
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div style={containerStyle}>
      <div style={overlayStyle}></div>
      <div style={contentStyle}>
        <h1 style={{ fontSize: "2.5rem", color: "#fff", textAlign: "center", marginBottom: "2rem", textTransform: "uppercase" }}>
          Faites-nous part de vos besoins
        </h1>
        <div style={{ textAlign: "center" }}>
          <button
            style={buttonStyle}
            onClick={() => toggleForm("demo")}
          >
            Demander une démo
          </button>
          <button
            style={{ ...buttonStyle, marginLeft: "1rem" }}
            onClick={() => toggleForm("contact")}
          >
            Contactez-nous
          </button>
        </div>
        {renderForm()}
      </div>
    </div>
  );
};

export default ContactSection;
