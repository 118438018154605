import React, { useState } from "react";
import jambarIcon from "../../assets/images/jambar.png";
import mansaIcon from "../../assets/images/mansa.png";
import surDevisIcon from "../../assets/images/petitlogo.png";

const ComparisonSection = () => {
  const [selectedPack, setSelectedPack] = useState(null);

  const packs = [
    {
      name: "JAMBAR",
      icon: <img src={jambarIcon} alt="JAMBAR" style={{ width: "50px" }} />,
      advantages: [
        "CENTRALISATION DES DONNEES CLIENTS",
        "GESTION DES DEVIS ET FACTURES",
        "TABLEAUX DE BORD SIMPLES ET INTUITIFS",
      ],
    },
    {
      name: "MANSA",
      icon: <img src={mansaIcon} alt="MANSA" style={{ width: "50px" }} />,
      advantages: [
        "GESTION COMPLETE DES PROSPECTS",
        "CAMPAGNE MARKETING",
        "SUIVI DES PERFORMANCES (KPI)",
        "RAPPORTS BASEES SUR L'ANALYSE DE DONNEES",
      ],
    },
    {
      name: "SUR DEVIS",
      icon: <img src={surDevisIcon} alt="SUR DEVIS" style={{ width: "50px" }} />,
      advantages: ["SOLUTIONS PERSONNALISEES", "API SUR MESURE"],
    },
  ];

  const features = [
    { name: "Agenda", JAMBAR: true, MANSA: true },
    { name: "Leads", JAMBAR: true, MANSA: true },
    { name: "Objectifs", JAMBAR: false, MANSA: true },
    { name: "Devis", JAMBAR: true, MANSA: true },
    { name: "Contrats", JAMBAR: false, MANSA: true },
    { name: "Clients", JAMBAR: true, MANSA: true },
    { name: "Facturation", JAMBAR: true, MANSA: true },
    { name: "Note de crédit", JAMBAR: false, MANSA: true },
    { name: "Collaborateur", JAMBAR: false, MANSA: true },
    { name: "Syncro Mail", JAMBAR: true, MANSA: true },
    { name: "Syncro Drive", JAMBAR: false, MANSA: true },
    { name: "Taches", JAMBAR: true, MANSA: true },
    { name: "Rapports avancés", JAMBAR: false, MANSA: true },
    { name: "Suivi performance KPI", JAMBAR: false, MANSA: true },
    { name: "Live Chat", JAMBAR: false, MANSA: true },
    { name: "Support", JAMBAR: false, MANSA: true },
  ];

  const styles = {
    container: {
      padding: "2rem",
      fontFamily: "Lato, sans-serif",
      backgroundColor: "#f9f9f9",
    },
    heading: {
      textAlign: "center",
      fontSize: "2.5rem",
      fontWeight: "bold",
      color: "#331e62",
      marginBottom: "2rem",
    },
    layout: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: "2rem",
    },
    featuresContainer: {
      flex: 1,
      maxWidth: "50%",
      overflowX: "auto",
    },
    packsContainer: {
      flex: 1,
      maxWidth: "50%",
    },
    pack: {
      textAlign: "center",
      border: "2px solid #20c5e2",
      borderRadius: "10px",
      padding: "1rem",
      backgroundColor: "#ffffff",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      marginBottom: "1rem",
      width: "100%",
    },
    packSelected: {
      transform: "scale(1.05)",
      boxShadow: "0 6px 15px rgba(0, 0, 0, 0.2)",
      borderColor: "#331e62",
    },
    button: {
      backgroundColor: "#20c5e2",
      color: "#ffffff",
      border: "none",
      borderRadius: "5px",
      padding: "0.5rem 0.8rem",
      cursor: "pointer",
      marginTop: "1rem",
      fontWeight: "bold",
      fontSize: "0.9rem",
    },
    table: {
      width: "100%",
      minWidth: "600px",
      borderCollapse: "collapse",
      backgroundColor: "#ffffff",
    },
    th: {
      padding: "1rem",
      backgroundColor: "#331e62",
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
      border: "1px solid #fff",
    },
    td: {
      padding: "0.8rem",
      textAlign: "center",
      border: "1px solid #ddd",
    },
    featureName: {
      textAlign: "left",
      fontWeight: "bold",
      color: "#331e62",
    },
    "@media (max-width: 768px)": {
      layout: {
        flexDirection: "column",
      },
      featuresContainer: {
        order: 1,
        maxWidth: "100%",
        marginBottom: "1rem",
      },
      packsContainer: {
        order: 2,
        maxWidth: "100%",
      },
      heading: {
        fontSize: "1.5rem",
      },
      container: {
        padding: "1rem",
      },
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Besoin d'aide pour choisir la formule la plus adaptée ?</h2>
      <div style={styles.layout}>
        <div style={styles.featuresContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Caractéristiques</th>
                <th style={styles.th}>JAMBAR</th>
                <th style={styles.th}>MANSA</th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature, idx) => (
                <tr key={idx}>
                  <td style={{ ...styles.td, ...styles.featureName }}>{feature.name}</td>
                  <td style={styles.td}>{feature.JAMBAR ? "✔️" : ""}</td>
                  <td style={styles.td}>{feature.MANSA ? "✔️" : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={styles.packsContainer}>
          {packs.map((pack, idx) => (
            <div
              key={idx}
              style={{
                ...styles.pack,
                ...(selectedPack === idx ? styles.packSelected : {}),
              }}
            >
              <div>{pack.icon}</div>
              <div>{pack.name}</div>
              <button
                style={styles.button}
                onClick={() =>
                  setSelectedPack(selectedPack === idx ? null : idx)
                }
              >
                {selectedPack === idx ? "Masquer" : "En savoir plus"}
              </button>
              {selectedPack === idx && (
                <div>
                  <strong>Avantages :</strong>
                  <ul>
                    {pack.advantages.map((adv, i) => (
                      <li key={i}>⭐ {adv}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComparisonSection;