import React, { useEffect, useState } from 'react';
import backgroundImage from '../assets/images/backPricing.jpg';
import ValuesSection from '../components/AboutUs/ValuesSection';
import Vision from '../components/AboutUs/Vision';
import VisionSection from '../components/AboutUs/VisionSection';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';

const AboutUs = () => {
  const [,setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const backgroundSectionStyle = {
    position: 'relative',
    height: '60vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '80px',
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  };

  const textContainerStyle = {
    position: 'relative',
    zIndex: 2,
    fontFamily: 'Lato, sans-serif',
    textAlign: 'center',
    color: '#ffffff',
    padding: '0 2rem',
    maxWidth: '700px',
  };

  const headingStyle = {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    fontWeight: 'bold',
    marginBottom: '1rem',
  };

  const paragraphStyle = {
    fontSize: 'clamp(1rem, 2.5vw, 1.2rem)',
  };

  return (
    <>
      <Header />
      <div style={backgroundSectionStyle}>
        <div style={overlayStyle}></div>
        <div style={textContainerStyle}>
          <h1 style={headingStyle}>Découvrez ce qui nous anime</h1>
          <p style={paragraphStyle}>
          Notre mission, notre vision et les valeurs qui inspirent chacune de nos actions au quotidien
          </p>
        </div>
      </div>
      <Vision />
      <ValuesSection />
      <VisionSection />
      <Footer />
    </>
  );
};

export default AboutUs;
