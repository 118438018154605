// src/components/Common/BackToTopButton.js
import React, { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const backToTopButtonStyle = {
   position: 'fixed',
   bottom: '20px',
   right: '20px',
   backgroundColor: '#20c5e2',
   color: '#fff',
   borderRadius: '50%',
   width: '50px',
   height: '50px',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   cursor: 'pointer',
   boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
   zIndex: 1000,
   transition: 'opacity 0.3s',
   opacity: 0,
   fontFamily: 'Lato, sans-serif',
};

const BackToTopButton = () => {
   const [isVisible, setIsVisible] = useState(false);

   const toggleVisibility = () => {
      if (window.scrollY > 300) {
         setIsVisible(true);
      } else {
         setIsVisible(false);
      }
   };

   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
   };

   useEffect(() => {
      window.addEventListener('scroll', toggleVisibility);
      return () => window.removeEventListener('scroll', toggleVisibility);
   }, []);

   return (
      isVisible && (
         <div onClick={scrollToTop} style={{ ...backToTopButtonStyle, opacity: 1 }}>
            <FaArrowUp size={24} />
         </div>
      )
   );
};

export default BackToTopButton;
