import React, { useState } from "react";
import { FaCommentAlt, FaPhoneAlt, FaTimes, FaWhatsapp } from "react-icons/fa";

const containerStyle = {
  position: "fixed",
  bottom: "20px",
  left: "10px",
  backgroundColor: "#20c5e2",
  color: "#fff",
  borderRadius: "15px",
  width: "50px",
  height: "150px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
  zIndex: 1000,
  cursor: "pointer",
  top: "500px"
};

const iconStyle = {
  color: "#fff",
  fontSize: "1.5rem",
  cursor: "pointer",
  transition: "transform 0.2s ease",
};

const chatWindowStyle = {
  position: "fixed",
  bottom: "80px",
  left: "100px",
  width: "320px",
  maxHeight: "400px",
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
  overflow: "hidden",
  zIndex: 1000,
  display: "flex",
  flexDirection: "column",
};

const chatHeaderStyle = {
  backgroundColor: "#20c5e2",
  color: "#fff",
  padding: "10px",
  textAlign: "center",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const chatBodyStyle = {
  flex: 1,
  padding: "10px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
};

const callWindowStyle = {
  position: "fixed",
  bottom: "80px",
  left: "100px",
  width: "300px",
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
  padding: "15px",
  zIndex: 1000,
  animation: "fadeIn 0.3s ease",
};

const callOptionStyle = {
  marginBottom: "10px",
  padding: "10px",
  border: "1px solid #20c5e2",
  borderRadius: "10px",
  backgroundColor: "#f4f8fa",
  color: "#333",
  textAlign: "center",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "bold",
  transition: "background-color 0.3s ease",
};

const UnifiedChat = () => {
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [isCallOpen, setIsCallOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const questions = [
    {
      text: "Bonjour ! Que souhaitez-vous savoir ?",
      options: [
        { text: "Modules disponibles", next: 1 },
        { text: "Prix et abonnements", next: 2 },
        { text: "Discutez avec un de nos experts", next: 3 },
      ],
    },
    {
      text: "Nos modules incluent la gestion client, la gestion de projet et bien plus !",
      options: [{ text: "Retour", next: 0 }],
    },
    {
      text: "Nos abonnements commencent à partir de 7.000 F.CFA/mois. Consultez la page des offres pour plus d'infos.",
      options: [{ text: "Retour", next: 0 }],
    },
    {
      text: "Contactez-nous à contact@syncro.sn ou au +221 77 251 88 88",
      options: [{ text: "Retour", next: 0 }],
    },
  ];

  const toggleChatBot = () => {
    setIsChatBotOpen(!isChatBotOpen);
    setIsCallOpen(false);
    if (!isChatBotOpen && messages.length === 0) {
      setMessages([{ text: questions[0].text, sender: "bot" }]);
    }
  };

  const toggleCallWindow = () => {
    setIsCallOpen(!isCallOpen);
    setIsChatBotOpen(false);
  };

  const handleOptionClick = (option) => {
    const nextQuestion = questions[option.next];
    setMessages((prev) => [...prev, { text: nextQuestion.text, sender: "bot" }]);
    setCurrentQuestion(option.next);
  };

  return (
    <>
      <div style={containerStyle}>
        <div onClick={toggleChatBot}>
          <FaCommentAlt style={iconStyle} title="ChatBot" />
        </div>
        <div
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=221772518888", "_blank")
          }
        >
          <FaWhatsapp style={iconStyle} title="WhatsApp" />
        </div>
        <div onClick={toggleCallWindow}>
          <FaPhoneAlt style={iconStyle} title="Call" />
        </div>
      </div>

      {/* ChatBot Window */}
      {isChatBotOpen && (
        <div style={chatWindowStyle}>
          <div style={chatHeaderStyle}>
            <span>ChatBot</span>
            <FaTimes onClick={toggleChatBot} style={{ cursor: "pointer" }} />
          </div>
          <div style={chatBodyStyle}>
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  alignSelf: msg.sender === "bot" ? "flex-start" : "flex-end",
                  backgroundColor: msg.sender === "bot" ? "#20c5e2" : "#f0f0f0",
                  color: msg.sender === "bot" ? "#fff" : "#333",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "5px",
                  maxWidth: "70%",
                }}
              >
                {msg.text}
              </div>
            ))}
            {questions[currentQuestion]?.options?.map((option, index) => (
              <div
                key={index}
                style={callOptionStyle}
                onClick={() => handleOptionClick(option)}
              >
                {option.text}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Call Window */}
      {isCallOpen && (
        <div style={callWindowStyle}>
          <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>
            Appelez nous !
          </h3>
          <div style={callOptionStyle}>Entreprise: 33 849 44 94</div>
          <div style={callOptionStyle}>Commercial: 77 251 88 88</div>
        </div>
      )}
    </>
  );
};

export default UnifiedChat;
