import React from 'react';
import { FaEnvelope, FaFacebook, FaLinkedin, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from 'react-icons/fa';

const Footer = () => {
    const footerStyle = {
        padding: '2rem 3rem',
        background: 'linear-gradient(90deg, #331e62, #20c5e2)',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '2rem',
        fontFamily: 'Lato, sans-serif',
    };

    const columnStyle = {
        flex: '1 1 200px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    };

    const columnFollowUsStyle = {
        ...columnStyle,
        transform: 'translateX(130px)', // Décale vers la droite
    };

    const titleStyle = {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#ffffff',
        marginBottom: '1rem',
        fontFamily: 'Lato, sans-serif',
    };

    const linkStyle = {
        color: '#ffffff',
        textDecoration: 'none',
        fontSize: '0.9rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        cursor: 'pointer',
        fontFamily: 'Lato, sans-serif',
    };

    const iconStyle = {
        color: '#ffffff',
        fontSize: '1.5rem',
        transition: 'color 0.3s',
    };

    return (
        <footer style={footerStyle}>
            {/* Nos Solutions */}
            <div style={columnStyle}>
                <h4 style={titleStyle}>Solutions</h4>
                <span style={linkStyle}>Gestion des clients</span>
                <span style={linkStyle}>Suivi de projet</span>
                <span style={linkStyle}>Automatisation des tâches</span>
                <span style={linkStyle}>Analytics et rapports</span>
            </div>

            {/* Ressources */}
            <div style={columnStyle}>
                <h4 style={titleStyle}>Ressources</h4>
                <span style={linkStyle}>Formation</span>
                <span style={linkStyle}>Documentation</span>
                <span style={linkStyle}>Tutoriels vidéo</span>
                <span style={linkStyle}>Assistance technique</span>
            </div>

            {/* Contactez-Nous */}
            <div style={columnStyle}>
                <h4 style={titleStyle}>Contactez-nous</h4>
                <div style={linkStyle}>
                    <FaMapMarkerAlt style={iconStyle} />
                    <span>Immeuble Ndama, Avenue Bourguiba, Dakar, Sénégal</span>
                </div>
                <div style={linkStyle}>
                    <FaPhoneAlt style={iconStyle} />
                    <span>+221 33 849 44 94 // +221 77 251 88 88</span>
                </div>
                <div style={linkStyle}>
                    <FaEnvelope style={iconStyle} />
                    <span>contact@syncro.sn</span>
                </div>
            </div>

            {/* Suivez-Nous */}
            <div style={columnFollowUsStyle}>
                <h4 style={titleStyle}>Suivez-nous</h4>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebook style={iconStyle} />
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <FaTwitter style={iconStyle} />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin style={iconStyle} />
                    </a>
                </div>

                {/* Image ajoutée sous les icônes */}
                <div
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                    style={{
                        marginTop: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={require('../../assets/images/sn.png')}
                        alt="Retour en haut"
                        style={{
                            width: '200px',
                            height: '50px',
                            objectFit: 'contain',
                            transform: 'translateX(-130px)',
                        }}
                    />
                </div>
            </div>

            {/* Styles Responsives */}
            <style jsx>{`
                @media (max-width: 768px) {
                    footer {
                        flex-direction: column;
                        padding: 1rem;
                    }

                    .columnStyle, .columnFollowUsStyle {
                        flex: 1 1 100%;
                        transform: none;
                    }

                    .columnFollowUsStyle {
                        transform: none;
                    }

                    .iconStyle {
                        font-size: 1.2rem;
                    }

                    .titleStyle {
                        font-size: 1rem;
                    }

                    footer img {
                        width: 100px;
                    }

                    .linkStyle {
                        font-size: 0.8rem;
                    }
                }

                @media (max-width: 768px) {
                    footer {
                        padding: 1rem;
                    }

                    .columnStyle, .columnFollowUsStyle {
                        flex: 1 1 100%;
                        transform: none;
                    }

                    .linkStyle {
                        font-size: 0.7rem;
                    }

                    footer img {
                        width: 80px;
                    }

                    .iconStyle {
                        font-size: 1rem;
                    }

                    .titleStyle {
                        font-size: 1rem;
                    }
                }
            `}</style>
        </footer>
    );
};

export default Footer;