import React from 'react';

const sectionStyle = {
  padding: '4rem 2rem',
  color: '#002244',
  textAlign: 'center',
  backgroundColor: '#f9f9f9',
};

const titleStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  color: 'black',
  marginBottom: '1rem',
  fontFamily: 'Raleway',
};

const valuesContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '2rem',
};

const valueItemStyle = {
  width: '350px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '3rem',
};


const subTitleStyle = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '0.5rem',
  fontFamily: 'Lato, sans-serif',
};

const descriptionStyle = {
  fontSize: '1rem',
  color: '#555',
  fontFamily: 'Lato, sans-serif',
  lineHeight: '1.6',
  textAlign: 'center',
};

const lineStyle = {
  width: '50px',
  height: '4px',
  backgroundColor: '#20c5e2',
  margin: '1rem auto',
  boxShadow: '0px 4px 8px rgba(78, 177, 226, 0.5)',
};

const ValuesSection = () => {
  const iconContainerStyle = {
    fontSize: '2rem',
    background: 'linear-gradient(45deg, #20c5e2, #331e62)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
  

  const values = [
    {
      title: 'Confiance',
      description: 'Au cœur de toutes nos actions',
      icon: <i className="fa-solid fa-handshake-angle" style={iconContainerStyle}></i>,
    },
    {
      title: 'Dévouement',
      description: 'Une équipe dédiée à votre réussite',
      icon: <i className="fa-solid fa-heart" style={iconContainerStyle}></i>,
    },
    {
      title: 'Responsabilité',
      description: "Engagés à agir avec intégrité",
      icon: <i className="fa-solid fa-user-tie" style={iconContainerStyle}></i>,
    },
    {
      title: 'Excellence',
      description: "L’exigence qui guide nos efforts",
      icon: <i className="fa-solid fa-award" style={iconContainerStyle}></i>,
    },
  ];
  

  

  return (
    <section style={sectionStyle}>
      <h2 style={titleStyle}>Nos valeurs</h2>
      <p style={{ fontSize: '1.3rem', lineHeight: '1.6', color: '#333', marginBottom: '3rem' }}>
      Plus que de simples mots, ces valeurs sont une véritable promesse d’accompagnement et de réussite commune. 
      Elles incarnent le cœur de notre engagement à vos côtés
      </p>
      <div style={valuesContainerStyle}>
        {values.map((value, index) => (
          <div key={index} style={valueItemStyle}>
            <div>{value.icon}</div>
            <h3 style={subTitleStyle}>{value.title}</h3>
            <p style={descriptionStyle}>{value.description}</p>
            <div style={lineStyle}></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ValuesSection;
