// src/App.js
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import BackToTopButton from './components/Common/BackToTopButton';
import UnifiedChat from './components/Common/UnifiedChat';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Ressources from './pages/Ressources';

function App() {
   return (
      <Router>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/ressources" element={<Ressources />} />
         </Routes>
         <BackToTopButton />
         <UnifiedChat />
      </Router>
   );
}

export default App;
